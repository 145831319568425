@mixin no-print {
  @media print {
    display: none;
  }
}

.Stickers {
  .phrase {
    font-family: 'Spartan', sans-serif;
    font-size: 58px;
    line-height: 0.95;
    letter-spacing: -6px;
  }
  .phrases-form {
    display: inline-flex;
    gap: 16px;
    @include no-print;
  }
  padding: 12px;
  @media print {
    padding: 0;
  }
  details {
    @include no-print;
  }
  fieldset {
    display: flex;
    gap: 8px;
    flex-direction: column;
    justify-content: left;
    align-content: flex-start;
    input {
      width: fit-content;
    }
    @include no-print;
  }
  label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include no-print;
  }
  .sticker-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0px;
    // padding: 12px;
  }
  .phrases-container {
    width: 100%;
    display: inline-grid;
    grid-template-columns: repeat(auto-fit, 300px);
    @media print {
      display: inline-grid !important;
    }
  }

  .sticker {
    border: 1px dashed grey;
    padding: 4px;
    line-height: 1;

    width: fit-content;
    &_svg {
      // border: 1px solid orangered;
      outline: orangered;
      display: inline-flex;
    }
    &_details {
      display: flex;
      justify-content: space-between;
    }
    &_id {
      padding-left: 16px;
    }
    &_title {
      padding-right: 16px;
    }
  }
}
