.skip-list {
  box-sizing: content-box;
  position: absolute;
  background-color: $spotify-bg;
  left: $sm;
  width: 300px;
  z-index: 3;
  border: 1px solid $spotify-white;

  border-radius: $md;

  .tabs-menu {
  }
  .quick-actions {
    padding: 2px $md * 1.5;
  }
}

.tabs {
  // display: flex;
  // flex: 1 1 80px;
  .tabs-menu {
    display: flex;
    flex: 1 1 80px;
    padding: $md;
    gap: 8px;
    button {
      padding: 8px 16px;
      border-radius: 4px;
      color: $spotify-white;
      text-decoration: none;
      cursor: pointer;
      background-color: transparent;
      text-align: left;

      @include btn-font;
      &.active {
        background-color: #333333;
      }
    }
  }
}
.tabs-content {
  padding: 2px $md;
  .row {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;

    summary,
    ul,
    h5 {
      margin: 0;
      font-size: 16px;
      min-width: 50px;
      align-self: baseline;
    }
    h5 {
      cursor: pointer;
      width: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  ul {
    width: 50%;
    flex-grow: 1;
    padding-left: 0;
    li {
      font-size: 14px;
      list-style-type: none;
      padding-left: 0;
    }
  }
  .disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  }
  .plus {
    cursor: pointer;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    &:hover {
      transform: scale(1.18);
    }
  }
  .is-collapsable {
    .DESC::after,
    .ASC::after {
      margin-left: $sm;
      background-image: url('../images/chevron-ACS.svg');
      background-size: 12px 12px;
      background-repeat: no-repeat;
      display: inline-block;
      width: 12px;
      height: 12px;
      content: '';
    }
  .DESC::after {
      background-image: url('../images/chevron-DESC.svg');
    }
  }
}
