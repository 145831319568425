.user-playlists {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: $md;
  margin-bottom: $md;
  gap: $sm;
  font-size: 21px;
  font-weight: 700;
  border-radius: 500px;

  .loading-status {
    font-size: $lg;
  }
  border: 1px solid white;
  cursor: pointer;

  min-width: 350px;
  @media (max-width: $bp-sm-min) {
    position: inherit;
  }
}
.s-kebab {
  // position: relative;
  display: flex;
  align-items: center;

  .kebab-btn {
    background: transparent;
    border: 0;
    color: hsla(0, 0%, 100%, 0.6);
    padding: 0;
    cursor: pointer;

    svg {
      fill: currentcolor;
    }
  }

  .s-menu {
    visibility: hidden;
    position: absolute;
    z-index: 6;
    top: $md;
    right: 0;
    left: 0;
  }
  .s-menu-child {
    position: relative;
    ul {
      width: initial;
      max-width: initial;
      min-width: initial;

      button {
        padding-left: 16px;
      }
      // box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.3),
      //   inset 0 6px 8px rgba(0, 0, 0, 0.2) !important;
      box-shadow: inset 4px 0 8px rgba(0, 0, 0, 0.3),
        inset 6px 0 8px rgba(0, 0, 0, 0.2) !important;
    }
  }
  .closed {
    visibility: hidden;
  }

  .open {
    visibility: visible;
  }
}

.s-menu {
  @include s-element;

  ul {
    background-color: $spotify-grey-menu;
    color: $spotify-white;
    border-radius: 4px;
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.3), 0 6px 8px rgba(0, 0, 0, 0.2);
    max-height: calc(100vh - 24px);
    width: 100%;
    max-width: 350px;
    min-width: 260px;
    overflow-y: auto;
    padding: 4px;

    li {
      @include s-element;

      button {
        @include s-element;
        cursor: pointer;
        background: transparent;
        border: 0;
        border-radius: 2px;
        text-decoration: none;

        gap: 8px;
        align-items: center;
        color: hsla(0, 0%, 100%, 0.9);
        display: flex;
        height: 40px;
        justify-content: space-between;
        padding: 12px;
        padding-inline-end: 8px;
        position: relative;
        text-align: start;

        user-select: none;
        width: 100%;
        &:hover {
          background-color: hsla(0, 0%, 100%, 0.1);
          color: #fff;
          text-decoration: none;
        }
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        svg {
          transform: rotate(90deg);
          fill: currentcolor;
        }
      }
    }
  }
}
.highlight {
  color: $spotify-green;
}
.logout-menu {
  position: absolute;
  ul {
    min-width: 80px;
    margin-top: 4px;
  }
}
