@import url(https://use.typekit.net/rje0nrm.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loading {
  stroke-width: 15px;
  animation: stroke 2s infinite; }

@keyframes stroke {
  0% {
    stroke-width: 2px; }
  5% {
    stroke-width: 0px; }
  0% {
    stroke-width: 0px; }
  50% {
    stroke-width: 15px; }
  95% {
    stroke-width: 5px; }
  100% {
    stroke-width: 2px; } }

.app-grid {
  padding: 21px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: minmax(auto, auto);
  grid-template-areas: 'toptable toptable toptable' '... device  ...' 'stats player  playlists' 'results results  playlists'; }

.top-table {
  grid-area: toptable; }

.device-area {
  grid-area: device; }

.player-area {
  justify-self: center;
  grid-area: player; }

.results-area {
  grid-area: results; }

.stats-area {
  grid-area: stats; }

.playlists-area {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  grid-area: playlists;
  z-index: 3; }

@media (max-width: 0px) {
  .hide-up-xs {
    display: none; } }

@media (min-width: 0px) {
  .hide-xs {
    display: none; } }

@media (max-width: 960px) {
  .hide-up-md {
    display: none; }
  .app-grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: '... device  ...' '... player  playlists' '... results  playlists' '... results  playlists'; } }

@media (min-width: 960px) {
  .hide-md {
    display: none; } }

@media (max-width: 1280px) {
  .hide-up-lg {
    display: none; } }

@media (min-width: 1280px) {
  .hide-lg {
    display: none; } }

@media (max-width: 1920px) {
  .hide-up-xl {
    display: none; } }

@media (min-width: 1920px) {
  .hide-xl {
    display: none; } }

@media (max-width: 900px) {
  .hide-up-sm {
    display: none; }
  .app-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-template-areas: 'toptable' 'device' 'playlists' 'player' 'results' 'stats'; } }

@media (min-width: 600px) {
  .hide-sm {
    display: none; } }

@media (max-width: 650px) {
  .hide-up-sm {
    display: none; } }

.flex {
  display: flex; }

.align {
  display: flex;
  align-items: center; }

.green {
  color: #1db954 !important; }

.sm-txt {
  font-size: 14px; }

body {
  margin: 0;
  padding: 0;
  font-family: 'proxima-nova', 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

tr td {
  border-bottom: 1pt solid rgba(255, 255, 255, 0.6); }

input[type='checkbox'] {
  min-width: 16px;
  min-height: 16px; }

.header {
  opacity: 0.6;
  text-transform: uppercase; }

.pointer {
  cursor: pointer; }

.action {
  cursor: pointer; }

/* touched animation */
.touched {
  animation: touched-success 1s; }

.touched-success {
  animation: touched-success 1s; }

.touched-error {
  animation: touched-error 1s; }

.success {
  color: #1db954;
  border: 1px solid #1db954; }

@keyframes touched-success {
  0% {
    color: #fff; }
  50% {
    color: #1db954; }
  100% {
    color: #fff; } }

@keyframes touched-error {
  0% {
    color: #fff; }
  50% {
    color: red; }
  100% {
    color: #fff; } }

/* SfCheck */
.sfCheck {
  display: inline;
  margin: 0 14px;
  position: relative;
  width: 21px;
  height: 21px;
  text-align: center; }
  .sfCheck .icon {
    position: absolute;
    opacity: 0.6;
    fill: none;
    stroke: white;
    width: 21px; }
  .sfCheck .sfCheck_tick {
    stroke: #1db954;
    opacity: 1; }
  .sfCheck .sfCheck_cross {
    opacity: 0; }

.sfCheck:hover .sfCheck_tick {
  opacity: 0; }

.sfCheck:hover .sfCheck_cross {
  opacity: 1; }

.sfCheck:hover .sfCheck_plus {
  opacity: 1; }

.s-input {
  display: flex;
  flex-direction: column;
  text-align: left; }
  .s-input textarea,
  .s-input input {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid transparent;
    border-radius: 4px;
    color: #ffffff;
    font-family: inherit;
    font-size: 14px;
    height: 40px;
    padding: 0 12px; }

.s-submit,
.s-button {
  border: 2px;
  border-width: 2px;
  padding: 0 24px;
  height: 32px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #ffffff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.76px;
  line-height: 18px;
  border-radius: 500px;
  transition: all 33ms cubic-bezier(0.3, 0, 0, 1);
  white-space: nowrap;
  will-change: transform;
  outline: none;
  text-transform: uppercase; }
  .s-submit:focus, .s-submit:hover,
  .s-button:focus,
  .s-button:hover {
    transform: scale(1.06); }
  .s-submit:disabled,
  .s-button:disabled {
    color: gray; }

.s-submit {
  color: #181818;
  background-color: #1db954; }
  .s-submit.secondary {
    background-color: #117a37; }
  .s-submit:disabled {
    color: #989898;
    background-color: #57e589; }

.action-btn-group {
  display: grid;
  justify-content: center; }
  .action-btn-group span {
    font-size: 10px; }

.grey {
  border-color: #ffffff;
  background-color: #282828;
  color: #ffffff; }

.right {
  float: right; }

button {
  border-style: none; }

.plus {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 1px;
  color: #000;
  height: 16px;
  width: 16px; }

.sp-checkbox {
  box-sizing: border-box;
  padding-bottom: 4px;
  padding-top: 4px;
  position: relative;
  min-height: 32px;
  display: flex;
  align-items: center; }
  .sp-checkbox .check-wrapper {
    border-radius: 11px;
    height: 24px;
    min-width: 42px;
    position: relative;
    background-color: #868686;
    cursor: pointer; }
    .sp-checkbox .check-wrapper:after {
      display: block;
      position: absolute;
      pointer-events: none;
      transition: border-color 200ms ease-in 0s;
      box-sizing: border-box;
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      top: -6px;
      left: -6px;
      background: transparent;
      border-radius: 14px;
      border: 3px solid transparent;
      content: ''; }
    .sp-checkbox .check-wrapper .circle {
      background: #ffffff;
      border-radius: 24px;
      height: 20px;
      width: 20px;
      position: absolute;
      top: 2px;
      right: auto;
      left: 2px;
      transition: all 0.1s ease 0s; }
  .sp-checkbox input:checked ~ .check-wrapper {
    background-color: #1db954; }
    .sp-checkbox input:checked ~ .check-wrapper .circle {
      left: auto;
      right: 2px; }

.hidden {
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  width: 1px; }

.pulse {
  border: 1px solid #ffffff;
  animation-name: pulse;
  animation-duration: 0.8s;
  animation-iteration-count: 2;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(); }

@keyframes pulse {
  to {
    border-color: #1db954; } }

.back-button {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  height: 32px;
  justify-content: center;
  position: relative;
  width: 32px; }
  .back-button svg {
    stroke: #ffffff;
    stroke-width: 1pt;
    height: 22px;
    opacity: 1;
    width: 22px; }

.spotify-link {
  text-decoration: none;
  color: #1db954; }
  .spotify-link:hover {
    text-decoration: underline; }

.App {
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.logo-container {
  height: 100%;
  width: 100%;
  animation: App-logo-spin infinite 20s linear;
  border: 2px solid white; }

.App-logo {
  animation: logo-spin-reverse infinite 20s linear;
  height: 25vmin; }

.hooks {
  fill: white;
  animation: logo-spin infinite 20s linear;
  margin-top: -25vmin; }

.App-header {
  background-color: #121212;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 21px 0; }

.party-blends {
  background-color: #121212;
  min-height: 100vh;
  color: white;
  font-size: calc(10px + 2vmin);
  text-align: center; }
  .party-blends footer {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(255, 255, 255, 0.6);
    padding: 8px 16px;
    background-color: #181818; }
    .party-blends footer div {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .party-blends footer a {
      text-decoration: none; }
    .party-blends footer img {
      margin-left: 4px; }

.party-blends-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 21px; }
  .party-blends-header .back-button-container {
    display: flex;
    align-items: center;
    cursor: pointer; }
  .party-blends-header.home {
    justify-content: center; }
  .party-blends-header .back-button-container {
    position: relative; }
    .party-blends-header .back-button-container small {
      position: absolute;
      left: 40px;
      white-space: nowrap; }
  .party-blends-header .desktop-spacer {
    width: 32px; }
  .party-blends-header main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left; }

.App-link {
  color: #61dafb; }

@keyframes logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes logo-spin-reverse {
  from {
    transform: rotate(360deg); }
  to {
    transform: rotate(0deg); } }

.with-results {
  border-radius: 14px 14px 0 0; }

.player {
  border: 1px solid aliceblue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 14px;
  padding: 21px;
  min-width: 360px;
  max-width: 70vw;
  justify-content: flex-start;
  position: relative; }
  .player .search-bar {
    display: flex;
    align-content: space-between;
    width: 100%;
    margin-top: 14px; }
    .player .search-bar .query {
      width: 100%;
      padding: 7px;
      padding-left: 14px;
      font-size: 1.1em;
      border-radius: 7px 0 0 7px; }
    .player .search-bar .submit {
      border-radius: 0 7px 0 0; }
      .player .search-bar .submit svg {
        width: 24px;
        height: 24px;
        stroke: black; }
    .player .search-bar .search__loading-circle {
      fill: transparent;
      stroke: #1DB954;
      stroke-miterlimit: 10;
      stroke-width: 4px; }
    .player .search-bar input {
      border: 0; }
    .player .search-bar input:focus, .player .search-bar button:focus {
      outline: none; }
  .player .select-types {
    margin-top: 0px;
    justify-content: space-between;
    font-size: 0.7em;
    text-align: center; }
    .player .select-types select {
      padding: 7px;
      border-radius: 0 0 7px 7px;
      border-width: 0px;
      -webkit-appearance: none; }
    .player .select-types select:focus {
      outline: none;
      text-decoration: underline; }
  .player .results {
    text-align: left;
    padding: 0;
    list-style-type: none;
    font-size: 0.8em; }
    .player .results .results__item {
      cursor: pointer;
      padding-bottom: 0.2em; }

.icon-button {
  font-size: calc(10px + 0.5vmin); }
  .icon-button {
    margin-right: 14px; }
  .icon-button svg {
    height: 1.8em;
    stroke: #ffffff;
    fill: none;
    opacity: 0.8; }

.action-button-group {
  position: absolute;
  display: flex; }

.download-links {
  line-height: 0;
  display: flex;
  justify-content: flex-end; }
  .download-links .download-logo {
    position: relative; }
    .download-links .download-logo .price {
      line-height: 1;
      position: absolute;
      top: -5px;
      right: -1px;
      background: rgba(29, 185, 84, 0.8);
      font-size: 10px;
      color: #ffffff; }
    .download-links .download-logo img {
      width: 40px;
      height: 40px;
      border-radius: 50%; }
      .download-links .download-logo img.sm img {
        width: 28px;
        height: 28px; }

.audio-controls {
  width: 100%; }
  .audio-controls .label {
    margin-bottom: 8px; }
  .audio-controls .audio-buttons {
    display: flex;
    justify-content: space-between;
    margin: 14px 0; }
    .audio-controls .audio-buttons .icon {
      cursor: pointer;
      text-align: center;
      border: 1px solid rgba(255, 255, 255, 0.6);
      color: rgba(255, 255, 255, 0.8);
      padding: 7px; }
    .audio-controls .audio-buttons .arrow-right {
      width: 0;
      height: 0;
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
      border-left: 21px solid transparent;
      border-left-color: #1db954; }
    .audio-controls .audio-buttons .square {
      width: 0;
      height: 0;
      border: 14px solid #ffffff; }
  .audio-controls .seek {
    width: 100%; }

.devices {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0px; }
  .devices ul {
    display: block;
    list-style-type: none;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    margin: 0; }
  .devices li {
    display: block; }

.playlists {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 14px;
  padding: 21px;
  font-size: calc(10px + 1vmin);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border-radius: 0 14px 14px 0;
  text-align: left;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-left-color: rgba(255, 255, 255, 0); }
  .playlists .playlist__item {
    cursor: pointer;
    list-style: none;
    padding-bottom: 7px;
    font-weight: bolder; }

.playlist__song {
  cursor: pointer;
  font-size: 0.8em; }

.playlist__song:hover {
  color: #1db954; }

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0; }

.results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 14px;
  padding: 21px;
  border-radius: 0 0 14px 14px;
  text-align: left;
  font-size: 0.9em; }

.next-icon__contatiner {
  text-align: center; }
  .next-icon__contatiner .next-icon {
    fill: #1db954; }

.popularity-meter {
  height: 14px; }

.top-table {
  margin: 14px;
  border-radius: 14px; }

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */ }

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s; }

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent; }

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1; }

.analysis-playlists {
  scroll-behavior: smooth; }
  .analysis-playlists.PartyPlaylistGroup {
    text-align: center; }
    .analysis-playlists.PartyPlaylistGroup h2 {
      margin-top: 0;
      margin-bottom: 0; }
    .analysis-playlists.PartyPlaylistGroup .description {
      margin-top: 0; }
    .analysis-playlists.PartyPlaylistGroup .actions {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px; }
      .analysis-playlists.PartyPlaylistGroup .actions .kebab-btn {
        position: relative; }
      .analysis-playlists.PartyPlaylistGroup .actions .s-menu {
        left: inherit;
        right: 16px;
        z-index: 6; }
    .analysis-playlists.PartyPlaylistGroup table {
      text-align: left; }
  .analysis-playlists .stat-check-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 0.7em;
    text-align: right;
    margin: 0 21px;
    justify-content: space-between;
    max-width: 600px; }
  .analysis-playlists .stat-checkbox {
    min-width: 120px;
    padding: 0 7px;
    margin: 0 7px;
    display: flex;
    flex: 1 1;
    justify-content: flex-end; }
  .analysis-playlists .test {
    position: relative; }
    .analysis-playlists .test .left {
      position: absolute;
      left: 0; }
  .analysis-playlists .playlist-info {
    display: flex;
    padding: 0 21px; }
    .analysis-playlists .playlist-info h4 {
      margin: 0 auto; }
    .analysis-playlists .playlist-info img {
      flex: 1 1;
      max-height: 100px;
      max-width: 100px; }
  .analysis-playlists .user-playlists {
    position: relative;
    display: flex;
    justify-content: center;
    grid-gap: 7px;
    gap: 7px; }
    .analysis-playlists .user-playlists .loading-status {
      font-size: 21px; }
  .analysis-playlists select {
    text-overflow: ellipsis; }
  .analysis-playlists .add-playlist {
    text-align: left;
    font-size: 0.7em;
    display: flex;
    flex-direction: column; }
    .analysis-playlists .add-playlist fieldset {
      border-radius: 7px; }
    .analysis-playlists .add-playlist textarea,
    .analysis-playlists .add-playlist input[type='text'] {
      min-width: 300px; }
    .analysis-playlists .add-playlist input {
      margin-top: 7px;
      margin-bottom: 7px; }

input[type='checkbox'] {
  cursor: pointer; }

.currently-playing {
  background-color: black;
  display: inline-flex;
  justify-content: center;
  border-radius: 7px;
  text-align: left; }
  .currently-playing legend {
    font-size: medium; }
  .currently-playing a {
    text-decoration: none;
    color: #1db954; }
    .currently-playing a:hover {
      text-decoration: underline; }
  .currently-playing .user-circles {
    margin-left: 32px; }

.title-head {
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .title-head span {
    width: 32px; }

.range-slider {
  margin: 21px 14px; }
  .range-slider .track {
    height: 8px;
    width: 100%;
    background-color: #ffffff; }
  .range-slider .thumb {
    height: 21px;
    width: 21px;
    border-radius: 50%;
    background-color: #1db954; }
    .range-slider .thumb::after {
      content: attr(data-value);
      position: absolute;
      top: 100%;
      left: 0;
      font-size: 14px;
      opacity: 0.8; }

.table-container {
  max-width: 100vw;
  display: flex;
  justify-content: center; }

.playlist-table {
  display: block;
  overflow-x: auto; }
  .playlist-table thead .table-average {
    text-align: center;
    font-size: 14px; }
  .playlist-table thead th {
    padding: 0 7px;
    margin-right: 23px;
    white-space: nowrap; }
    .playlist-table thead th:not(.table-average) {
      cursor: pointer;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.1em;
      line-height: 18px;
      text-transform: uppercase;
      color: #b3b3b3;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
    .playlist-table thead th .length {
      font-weight: normal; }
  @media (hover: hover) {
    .playlist-table tbody tr:not(.updating):hover {
      background-color: rgba(255, 255, 255, 0.1); } }
  .playlist-table td {
    font-size: 0.8em;
    border: 0px;
    padding: 8px 8px 8px; }

.ASC::before {
  height: 12px;
  padding-right: 7px;
  content: url(/static/media/chevron-ACS.59bb852c.svg); }

.DESC::before {
  height: 12px;
  padding-right: 7px;
  content: url(/static/media/chevron-DESC.e8a1c7f7.svg); }

.spotify-track {
  min-width: 220px;
  display: flex; }
  .spotify-track img,
  .spotify-track .img {
    margin-right: 16px; }
  .spotify-track .track-grid {
    text-align: left;
    align-items: center;
    grid-column-gap: 8px;
    column-gap: 8px;
    display: grid;
    grid-template: 'title title' 'badges subtitle' / auto 1fr; }
    .spotify-track .track-grid .title {
      color: #fff;
      grid-area: title;
      -webkit-user-select: none;
              user-select: none;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: normal;
      line-height: 24px;
      text-transform: none; }
    .spotify-track .track-grid .sub-title {
      grid-area: subtitle;
      grid-column-start: badges;
      color: #b3b3b3;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: normal;
      line-height: 16px;
      text-transform: none; }
    .spotify-track .track-grid .ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.user-playlists {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  margin-bottom: 14px;
  grid-gap: 7px;
  gap: 7px;
  font-size: 21px;
  font-weight: 700;
  border-radius: 500px;
  border: 1px solid white;
  cursor: pointer;
  min-width: 350px; }
  .user-playlists .loading-status {
    font-size: 21px; }
  @media (max-width: 640) {
    .user-playlists {
      position: inherit; } }

.s-kebab {
  display: flex;
  align-items: center; }
  .s-kebab .kebab-btn {
    background: transparent;
    border: 0;
    color: rgba(255, 255, 255, 0.6);
    padding: 0;
    cursor: pointer; }
    .s-kebab .kebab-btn svg {
      fill: currentcolor; }
  .s-kebab .s-menu {
    visibility: hidden;
    position: absolute;
    z-index: 6;
    top: 14px;
    right: 0;
    left: 0; }
  .s-kebab .s-menu-child {
    position: relative; }
    .s-kebab .s-menu-child ul {
      width: initial;
      max-width: initial;
      min-width: initial;
      box-shadow: inset 4px 0 8px rgba(0, 0, 0, 0.3), inset 6px 0 8px rgba(0, 0, 0, 0.2) !important; }
      .s-kebab .s-menu-child ul button {
        padding-left: 16px; }
  .s-kebab .closed {
    visibility: hidden; }
  .s-kebab .open {
    visibility: visible; }

.s-menu {
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline; }
  .s-menu ul {
    background-color: #282828;
    color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.3), 0 6px 8px rgba(0, 0, 0, 0.2);
    max-height: calc(100vh - 24px);
    width: 100%;
    max-width: 350px;
    min-width: 260px;
    overflow-y: auto;
    padding: 4px; }
    .s-menu ul li {
      border: 0;
      margin: 0;
      padding: 0;
      vertical-align: baseline; }
      .s-menu ul li button {
        border: 0;
        margin: 0;
        padding: 0;
        vertical-align: baseline;
        cursor: pointer;
        background: transparent;
        border: 0;
        border-radius: 2px;
        text-decoration: none;
        grid-gap: 8px;
        gap: 8px;
        align-items: center;
        color: rgba(255, 255, 255, 0.9);
        display: flex;
        height: 40px;
        justify-content: space-between;
        padding: 12px;
        -webkit-padding-end: 8px;
                padding-inline-end: 8px;
        position: relative;
        text-align: start;
        -webkit-user-select: none;
                user-select: none;
        width: 100%; }
        .s-menu ul li button:hover {
          background-color: rgba(255, 255, 255, 0.1);
          color: #fff;
          text-decoration: none; }
        .s-menu ul li button span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
        .s-menu ul li button svg {
          transform: rotate(90deg);
          fill: currentcolor; }

.highlight {
  color: #1db954; }

.logout-menu {
  position: absolute; }
  .logout-menu ul {
    min-width: 80px;
    margin-top: 4px; }

.skip-list {
  box-sizing: content-box;
  position: absolute;
  background-color: #181818;
  left: 7px;
  width: 300px;
  z-index: 3;
  border: 1px solid #ffffff;
  border-radius: 14px; }
  .skip-list .quick-actions {
    padding: 2px 21px; }

.tabs .tabs-menu {
  display: flex;
  flex: 1 1 80px;
  padding: 14px;
  grid-gap: 8px;
  gap: 8px; }
  .tabs .tabs-menu button {
    padding: 8px 16px;
    border-radius: 4px;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 16px;
    text-transform: none;
    color: #ffffff; }
    .tabs .tabs-menu button.active {
      background-color: #333333; }

.tabs-content {
  padding: 2px 14px; }
  .tabs-content .row {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 4px;
    gap: 4px; }
    .tabs-content .row summary,
    .tabs-content .row ul,
    .tabs-content .row h5 {
      margin: 0;
      font-size: 16px;
      min-width: 50px;
      align-self: baseline; }
    .tabs-content .row h5 {
      cursor: pointer;
      width: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
  .tabs-content ul {
    width: 50%;
    flex-grow: 1;
    padding-left: 0; }
    .tabs-content ul li {
      font-size: 14px;
      list-style-type: none;
      padding-left: 0; }
  .tabs-content .disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none; }
  .tabs-content .plus {
    cursor: pointer;
    border-radius: 50%;
    height: 24px;
    width: 24px; }
    .tabs-content .plus:hover {
      transform: scale(1.18); }
  .tabs-content .is-collapsable .DESC::after,
  .tabs-content .is-collapsable .ASC::after {
    margin-left: 7px;
    background-image: url(/static/media/chevron-ACS.59bb852c.svg);
    background-size: 12px 12px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 12px;
    height: 12px;
    content: ''; }
  .tabs-content .is-collapsable .DESC::after {
    background-image: url(/static/media/chevron-DESC.e8a1c7f7.svg); }

.user-circles {
  display: inline-flex;
  flex-wrap: wrap;
  position: relative;
  grid-gap: 8px 0;
  gap: 8px 0;
  margin: 0 16px 0 32px; }
  .user-circles.small {
    margin: 0 0 0 16px;
    flex-wrap: initial; }
  .user-circles .tooltip {
    display: inherit;
    border-bottom: inherit; }
  .user-circles .tooltiptext {
    z-index: 5;
    line-height: 14px; }
    .user-circles .tooltiptext span {
      line-height: normal; }
  .user-circles small {
    font-size: 10px; }

.user-circle__txt, .user-circle__img {
  background-color: white;
  box-shadow: 2px 2px 2px #404040;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: -15px;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-content: center;
  justify-content: center; }
  .user-circle__txt:hover, .user-circle__img:hover {
    transform: scale(1.3); }

.user-circle__txt {
  font-size: 24px;
  -webkit-user-select: none;
          user-select: none; }

.user-circle .small {
  width: 30px;
  height: 30px;
  font-size: 18px; }

.user-circle:hover {
  z-index: 3; }

.info {
  z-index: 2;
  background-color: #1db954; }

.share-buttons {
  display: inline-flex; }
  .share-buttons image {
    cursor: pointer; }
  .share-buttons .mobile-share-buttons {
    display: none; }
    @media (hover: none) {
      .share-buttons .mobile-share-buttons {
        display: inherit; } }
  .share-buttons .copy-to-clipboard {
    background-color: white;
    height: 42px;
    width: 42px;
    padding: 4px;
    margin-right: 1px;
    border-radius: 50%;
    position: relative;
    line-height: 1; }
    .share-buttons .copy-to-clipboard span {
      color: #1db954;
      font-size: 16px; }
      .share-buttons .copy-to-clipboard span small {
        font-size: 12px; }

.qr-code {
  margin-top: 8px; }

.checkbox-list {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 24px; }
  .checkbox-list input {
    margin-right: 16px; }

.Stickers {
  padding: 12px; }
  .Stickers .phrase {
    font-family: 'Spartan', sans-serif;
    font-size: 58px;
    line-height: 0.95;
    letter-spacing: -6px; }
  .Stickers .phrases-form {
    display: inline-flex;
    grid-gap: 16px;
    gap: 16px; }
    @media print {
      .Stickers .phrases-form {
        display: none; } }
  @media print {
    .Stickers {
      padding: 0; } }
  @media print {
    .Stickers details {
      display: none; } }
  .Stickers fieldset {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    flex-direction: column;
    justify-content: left;
    align-content: flex-start; }
    .Stickers fieldset input {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content; }
    @media print {
      .Stickers fieldset {
        display: none; } }
  .Stickers label {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    @media print {
      .Stickers label {
        display: none; } }
  .Stickers .sticker-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px;
    gap: 0px; }
  .Stickers .phrases-container {
    width: 100%;
    display: inline-grid;
    grid-template-columns: repeat(auto-fit, 300px); }
    @media print {
      .Stickers .phrases-container {
        display: inline-grid !important; } }
  .Stickers .sticker {
    border: 1px dashed grey;
    padding: 4px;
    line-height: 1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .Stickers .sticker_svg {
      outline: orangered;
      display: inline-flex; }
    .Stickers .sticker_details {
      display: flex;
      justify-content: space-between; }
    .Stickers .sticker_id {
      padding-left: 16px; }
    .Stickers .sticker_title {
      padding-right: 16px; }

.Tips {
  width: 80%;
  max-width: 425px;
  min-height: 160px;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 16px 32px;
  padding: 16px;
  font-size: medium;
  border-radius: 7px;
  text-align: left; }
  .Tips_title {
    border-bottom: 1px solid #ffffff; }
  .Tips_description {
    font-size: large; }
  .Tips_footer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end; }
  .Tips a {
    text-decoration: none;
    color: #1db954; }
    .Tips a:hover {
      text-decoration: underline; }
  .Tips .user-circles {
    margin-left: 32px; }

body {
  scroll-behavior: smooth; }

.dev {
  border: 1px solid lime; }

.pagination {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 7px 0; }
  .pagination svg {
    cursor: pointer;
    height: 21px;
    width: 21px;
    fill: white; }
    .pagination svg text {
      fill: black; }

.arrow-icon {
  cursor: pointer;
  height: 21px;
  width: 21px;
  fill: white; }
  .arrow-icon text {
    fill: black; }

