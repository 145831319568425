$inactive: grey;
$success: blue;
$error: red;

// sizes
$xs: 3.5px;
$sm: 7px;
$md: 14px;
$lg: 21px;
$xl: 42px;

// https://developer.spotify.com/branding-guidelines;
$spotify-green: #1db954;
$spotify-green-dark: #117a37;
$spotify-white: #ffffff;
$spotify-grey: #222326;
$spotify-grey-menu: #282828;
$spotify-charcoal: #404040; // from picker
$spotify-bg: #181818;
$grey-45: #757575;
//from spotify (eg RECENTLY PLAYED)
$header-opacity: 0.6;
