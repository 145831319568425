.user-circles {
  display: inline-flex;
  flex-wrap: wrap;
  position: relative;
  gap: 8px 0;
  margin: 0 16px 0 32px;
  &.small {
    margin: 0 0 0 16px;
    flex-wrap: initial;
  }
  .tooltip {
    display: inherit;
    border-bottom: inherit;
  }
  .tooltiptext {
    z-index: 5;
    line-height: 14px;
    span {
      line-height: normal;
    }
  }

  small {
    font-size: 10px;
  }
}
.user-circle {
  &__txt,
  &__img {
    background-color: white;
    box-shadow: 2px 2px 2px $spotify-charcoal;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: -15px;
    transition: all 0.2s ease-in-out;

    display: flex;
    align-content: center;
    justify-content: center;

    &:hover {
      transform: scale(1.3);
    }
  }
  &__txt {
    font-size: 24px;
    user-select: none;
  }
  .small {
    width: 30px;
    height: 30px;
    font-size: 18px;
  }
  &:hover {
    z-index: 3;
  }
}
.info {
  z-index: 2;
  background-color: $spotify-green;
}
