// Breakpoint options. Widths in PX to be mapped to the named sizes below.
// Generally you shouldn't need to change the defaults
$bp-xxs-min: 359 !default;
$bp-xs-min: 480 !default;
$bp-sm-min: 640 !default;
$bp-md-min: 768 !default;
$bp-lg-min: 1024 !default;
$bp-xl-min: 1244 !default;
$bp-xxl-min: 1410 !default;

.app-grid {
  padding: $lg;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: minmax(auto, auto);
  grid-template-areas:
    'toptable toptable toptable'
    '... device  ...'
    'stats player  playlists'
    'results results  playlists';
}

.top-table {
  grid-area: toptable;
}

.device-area {
  grid-area: device;
}

.player-area {
  justify-self: center;
  grid-area: player;
}

.results-area {
  grid-area: results;
}

.stats-area {
  grid-area: stats;
}

.playlists-area {
  position: sticky;
  top: 0;
  grid-area: playlists;
  z-index: 3;
}

@media (max-width: 0px) {
  .hide-up-xs {
    display: none;
  }
}

@media (min-width: 0px) {
  .hide-xs {
    display: none;
  }
}

@media (max-width: 960px) {
  .hide-up-md {
    display: none;
  }

  .app-grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      '... device  ...'
      '... player  playlists'
      '... results  playlists'
      '... results  playlists';
  }
}

@media (min-width: 960px) {
  .hide-md {
    display: none;
  }
}

@media (max-width: 1280px) {
  .hide-up-lg {
    display: none;
  }
}

@media (min-width: 1280px) {
  .hide-lg {
    display: none;
  }
}

@media (max-width: 1920px) {
  .hide-up-xl {
    display: none;
  }
}

@media (min-width: 1920px) {
  .hide-xl {
    display: none;
  }
}

@media (max-width: 900px) {
  .hide-up-sm {
    display: none;
  }

  .app-grid {
    // display: block;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-template-areas:
      'toptable'
      'device'
      'playlists'
      'player'
      'results'
      'stats';
  }
}

@media (min-width: 600px) {
  .hide-sm {
    display: none;
  }
}

@media (max-width: 650px) {
  .hide-up-sm {
    display: none;
  }
}
