
.loading {
  stroke-width: 15px;
  animation: stroke 2s infinite;
}

@keyframes stroke {
  0% {
    stroke-width: 2px;
  }
  5% {
    stroke-width: 0px;
  }
  0% {
    stroke-width: 0px;
  }
  50% {
    stroke-width: 15px;
  }
  95% {
    stroke-width: 5px;
  }
  100% {
    stroke-width: 2px;
  }
}