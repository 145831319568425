.flex {
  display: flex;
}
.align {
  display: flex;
  align-items: center;
}

.green {
  color: $spotify-green !important;
}

.sm-txt {
  font-size: $md;
}

@mixin no-list-style {
  ul {
    display: block;
    list-style-type: none;
    padding-inline-start: 0;
    margin: 0;
  }

  li {
    display: block;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'proxima-nova', 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

tr td {
  border-bottom: 1pt solid rgba(#fff, 0.6);
}

input[type='checkbox'] {
  min-width: 16px;
  min-height: 16px;
}

@mixin font-md {
  font-size: calc(10px + 2vmin);
}

@mixin font-sm {
  font-size: calc(10px + 1vmin);
}

@mixin font-xs {
  font-size: calc(10px + 0.5vmin);
}

.header {
  opacity: $header-opacity;
  text-transform: uppercase;
}

@mixin pointer {
  cursor: pointer;
}

.pointer {
  @include pointer;
}

.action {
  @include pointer;
}

/* touched animation */
.touched {
  animation: touched-success 1s;
}

.touched-success {
  animation: touched-success 1s;
}

.touched-error {
  animation: touched-error 1s;
}

.success {
  color: $spotify-green;
  border: 1px solid $spotify-green;
}

@keyframes touched-success {
  0% {
    color: #fff;
  }

  50% {
    color: $spotify-green;
  }

  100% {
    color: #fff;
  }
}

@keyframes touched-error {
  0% {
    color: #fff;
  }

  50% {
    color: $error;
  }

  100% {
    color: #fff;
  }
}

/* SfCheck */
.sfCheck {
  display: inline;
  margin: 0 $md;
  position: relative;
  width: $lg;
  height: $lg;
  text-align: center;

  .icon {
    position: absolute;
    opacity: 0.6;
    fill: none;
    stroke: white;
    width: $lg;
  }

  .sfCheck_tick {
    stroke: $spotify-green;
    opacity: 1;
  }

  .sfCheck_cross {
    opacity: 0;
  }
}

.sfCheck:hover {
  .sfCheck_tick {
    opacity: 0;
  }

  .sfCheck_cross {
    opacity: 1;
  }

  .sfCheck_plus {
    opacity: 1;
  }
}
// a input styled like spotify's web app
.s-input {
  display: flex;
  flex-direction: column;
  text-align: left;
  textarea,
  input {
    background: hsla(0, 0%, 100%, 0.1);
    border: 1px solid transparent;
    border-radius: 4px;
    color: $spotify-white;
    font-family: inherit;
    font-size: $md;
    height: 40px;
    padding: 0 12px;
  }
}

.s-submit,
.s-button {
  border: 2px;
  border-width: 2px;
  padding: 0 24px;
  height: 32px;
  width: fit-content;
  background-color: $spotify-white;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.76px;
  line-height: 18px;
  border-radius: 500px;
  transition: all 33ms cubic-bezier(0.3, 0, 0, 1);
  white-space: nowrap;
  will-change: transform;
  outline: none;
  text-transform: uppercase;

  &:focus,
  &:hover {
    transform: scale(1.06);
  }
  &:disabled {
    color: lighten(black, 50%);
  }
}

.s-submit {
  color: $spotify-bg;
  background-color: $spotify-green;
  &.secondary {
    background-color: $spotify-green-dark;
  }
  &:disabled {
    color: lighten($spotify-bg, 50%);
    background-color: lighten($spotify-green, 20%);
  }
}
.action-btn-group {
  display: grid;
  justify-content: center;

  span {
    font-size: 10px;
  }
}

.grey {
  border-color: $spotify-white;
  background-color: $spotify-grey-menu;
  color: $spotify-white;
}
.right {
  float: right;
}

// from spotify
// prettier-ignore
// a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, /* fieldset, */ figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
//   border: 0;
//   margin: 0;
//   padding: 0;
//   vertical-align: baseline;
// }
@mixin s-element {
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}
button {
  border-style: none;
}
@mixin btn-font {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 16px;
  text-transform: none;
  color: $spotify-white;
}

.plus {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 1px;
  color: #000;
  height: 16px;
  width: 16px;
}

.sp-checkbox {
  box-sizing: border-box;
  padding-bottom: 4px;
  padding-top: 4px;
  position: relative;
  min-height: 32px;
  display: flex;
  align-items: center;

  .check-wrapper {
    border-radius: 11px;
    height: 24px;
    min-width: 42px;
    position: relative;
    background-color: #868686;
    cursor: pointer;
    &:after {
      display: block;
      position: absolute;
      pointer-events: none;
      transition: border-color 200ms ease-in 0s;
      box-sizing: border-box;
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      top: -6px;
      left: -6px;
      background: transparent;
      border-radius: 14px;
      border: 3px solid transparent;
      content: '';
    }
    .circle {
      background: $spotify-white;
      border-radius: 24px;
      height: 20px;
      width: 20px;
      position: absolute;
      top: 2px;
      right: auto;
      left: 2px;
      transition: all 0.1s ease 0s;
    }
  }
  input:checked ~ .check-wrapper {
    background-color: $spotify-green;
    .circle {
      left: auto;
      right: 2px;
    }
  }
}
.hidden {
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  width: 1px;
}

.pulse {
  border: 1px solid $spotify-white;
  // top: 61px;
  // left: $sm;

  animation-name: pulse;
  animation-duration: 0.8s;
  animation-iteration-count: 2;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier();
}
@keyframes pulse {
  to {
    border-color: $spotify-green;
    // border: 2px solid $spotify-green;
    // top: 60px;
    // left: $sm - 1;
  }
}
.back-button {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;

  display: inline-flex;
  height: 32px;
  justify-content: center;
  position: relative;
  width: 32px;
  svg {
    stroke: $spotify-white;
    stroke-width: 1pt;
    height: 22px;
    opacity: 1;
    width: 22px;
  }
}

.spotify-link {
  text-decoration: none;
  color: $spotify-green;
  &:hover {
    text-decoration: underline;
  }
}
