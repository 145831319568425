.checkbox-list {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 24px;
  input {
    margin-right: 16px;
  }
}
