@mixin audio-buttons {
  display: flex;
  justify-content: space-between;
  margin: $md 0;

  .icon {
    cursor: pointer;
    text-align: center;
    border: 1px solid rgba($spotify-white, 0.6);
    color: rgba($spotify-white, 0.8);
    padding: $sm;
  }

  .arrow-right {
    width: 0;
    height: 0;
    border-top: $md solid transparent;
    border-bottom: $md solid transparent;
    border-left: $lg solid transparent;
    border-left-color: $spotify-green;
  }

  .square {
    width: 0;
    height: 0;
    border: $md solid $spotify-white;
  }
}

@mixin seek {
  .seek {
    width: 100%;
  }
}

@mixin container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(black, 0.8);
  border-radius: $md;

  padding: $lg;
}

.with-results {
  border-radius: $md $md 0 0;
}

.player {
  border: 1px solid aliceblue;
  @include container;
  min-width: 360px;
  max-width: 70vw;
  // height: 100%;
  justify-content: flex-start;
  position: relative;
  @import 'search-bar';
}

.icon-button {
  @include font-xs;

  & {
    margin-right: 14px;
  }

  svg {
    height: 1.8em;
    stroke: $spotify-white;
    fill: none;
    opacity: 0.8;
  }
}

.action-button-group {
  position: absolute;
  display: flex;
}

.download-links {
  line-height: 0;
  display: flex;
  justify-content: flex-end;
  .download-logo {
    position: relative;
    .price {
      line-height: 1;
      position: absolute;
      top: -5px;
      right: -1px;
      background: rgba($spotify-green, 0.8);
      font-size: 10px;
      color: $spotify-white;
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      &.sm img {
        width: 28px;
        height: 28px;
      }
    }
  }
}
.audio-controls {
  width: 100%;
  .label {
    margin-bottom: 8px;
  }

  .audio-buttons {
    @include audio-buttons;
  }

  @include seek;
}
